import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

// import Logo from './pic.jpg'

const Sidebar = ({ bizcard }) => (
  <>
    <aside className="sidebar">
      <header>
        <div className="about">
          {bizcard.frontmatter.logo && (
            <div className="cover-author-logo">
              <Link to="/">
                {bizcard.frontmatter.logo && (
                  <Img
                    key={bizcard.frontmatter.logo.childImageSharp.fluid.src}
                    fluid={bizcard.frontmatter.logo.childImageSharp.fluid}
                  />
                )}
              </Link>
            </div>
          )}
          <div className="cover-author-image">
            <Link to="/">
              {bizcard.frontmatter.img && (
                <Img
                  key={bizcard.frontmatter.img.childImageSharp.fluid.src}
                  fluid={bizcard.frontmatter.img.childImageSharp.fluid}
                />
              )}
            </Link>
          </div>
          {bizcard.frontmatter.name && (
            <div className="author-name">{bizcard.frontmatter.name}</div>
          )}
          {bizcard.frontmatter.title && (
            <div className="author-title">{bizcard.frontmatter.title}</div>
          )}
          {bizcard.frontmatter.company && (
            <div className="author-company">{bizcard.frontmatter.company}</div>
          )}
          <ul className="details-table">
            {bizcard.frontmatter.phoneOffice && (
              <li id="phone">			
                <a href={"tel:"+bizcard.frontmatter.phoneOffice} target="_blank">
                  <span className="fa fa-phone"></span>			
                  <span className="label">
                    Office
                  </span>
                    <span className="value">
                      {bizcard.frontmatter.phoneOffice}								
                    </span>
                </a>		
              </li>
            // <div className="author-phone-office">{bizcard.frontmatter.phone_office}</div>
            )}
            {bizcard.frontmatter.phoneMobile && (
              <li id="mobile">			
                <a href={"tel:"+bizcard.frontmatter.phoneMobile} target="_blank">
                  <span className="fa fa-mobile-phone"></span>			
                  <span className="label">
                    Mobile
                  </span>
                    <span className="value">
                      {bizcard.frontmatter.phoneMobile}								
                    </span>
                </a>		
              </li>
            )}
            {bizcard.frontmatter.email && (
              <li id="email">			
                <a href={"mailto:"+bizcard.frontmatter.email} target="_blank">
                  <span className="fa fa-envelope-o"></span>			
                  <span className="label">
                    Email
                  </span>
                    <span className="value">
                      {bizcard.frontmatter.email}								
                    </span>
                </a>		
              </li>
            )}
            {bizcard.frontmatter.website && (
              <li id="website">			
                <a href={"http://"+bizcard.frontmatter.website} target="_blank">
                  <span className="fa fa-desktop"></span>			
                  <span className="label">
                    Website
                  </span>
                    <span className="value">
                      {bizcard.frontmatter.website}								
                    </span>
                </a>		
              </li>
            )}
            {bizcard.frontmatter.address && (
              <li id="address">			
                <a href={"http://maps.google.com/?q="+bizcard.frontmatter.address} target="_blank">
                  <span className="fa fa-map-marker"></span>			
                  <span className="label">
                    Address
                  </span>
                    {/* <div className="value"> */}
                      <span className="address-2 value">
                        {bizcard.frontmatter.address}								
                      </span>
                    {/* </div> */}
                </a>		
              </li>
            )}
          </ul>
          <section className="contact">
            <h3 className="contact-title">Contact me</h3>
            <div className="contact-icon">
              <ul>
                {bizcard.frontmatter.twitter && (
                  <li>
                    <a
                      href={`${bizcard.frontmatter.twitter}`}
                      target="_blank"
                    >
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                  </li>
                )}
                {bizcard.frontmatter.facebook && (
                  <li>
                    <a
                      href={`${bizcard.frontmatter.facebook}`}
                      target="_blank"
                    >
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                  </li>
                )}
                {bizcard.frontmatter.linkedin && (
                  <li>
                    <a
                      href={`${bizcard.frontmatter.linkedin}`}
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </li>
                )}
                {bizcard.frontmatter.instagram && (
                  <li>
                    <a
                      href={`${bizcard.frontmatter.instagram}`}
                      target="_blank"
                    >
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                  </li>
                )}
                {bizcard.frontmatter.youtube && (
                  <li>
                    <a
                      href={`${bizcard.frontmatter.youtube}`}
                      target="_blank"
                    >
                      <i className="fa fa-youtube" aria-hidden="true" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </section>

          <div dangerouslySetInnerHTML={{ __html: bizcard.html }} />
        </div>
      </header>
      <footer>
        <div className="copyright">
          <p>
            {new Date().getFullYear()} &copy; {bizcard.frontmatter.name}
          </p>
        </div>
      </footer>
    </aside>
  </>
)

export default Sidebar
